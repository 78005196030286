import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import routes from './routerConfig'

Vue.use(Router);

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes
  });

const router = createRouter();

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
});

export default router