
import { getToken, setToken } from '@utils/util'

const state = {
  token: getToken(),
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
}

const actions = {
  async setToken({
    commit,
  }, { token }) {
    //save token 
    setToken(token);
    commit('SET_TOKEN', token);
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
