import Moment from "moment";

export function dateTime(value, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!value)
    return '';
  return Moment(value).format(format);
}

export function date(value, format = 'YYYY-MM-DD') {
  if (!value)
    return '';
  return Moment(value).format(format);
}

//车次类型
export function trainType(type) {
  return {
    "0": "本务",
    "1": "补机",
    "2": "单机",
    "9": "被救援",
  }[type] || '';
}