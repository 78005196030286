import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import '@/styles/base.scss';
import vant from 'vant'
import 'vant/lib/index.css';

// 引入全局组件
import './components';

import * as filters from '@utils/filters' // global filters

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.use(vant);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


window.addEventListener("resize", function () {
  if (
    document.activeElement.tagName == "INPUT"
    || document.activeElement.tagName == "TEXTAREA"
  ) {
    window.setTimeout(function () {
      document.activeElement.scrollIntoViewIfNeeded();
    }, 0);
  }
});


