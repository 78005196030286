
<template>
  <div id="app" :class="isGray ? 'filterClass' : ''">
    <router-view />
  </div>
</template>
<script>
  export default {
    data() {
      return {
        isGray: false,
      }
    },
    watch: {
      '$route': {
        immediate: true,
        deep: true,
        handler(val) {
          // if (val.path === '/clockIn' || val.path === '/clockOut') {
          //   this.isGray = true
          // } else {
          //   this.isGray = false
          // }
          console.log(val, 999)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .filterClass {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }

</style>
