
import qs from 'qs';

// copy json
export function copyJson(data) {
  return JSON.parse(JSON.stringify(data));
}

export function optimizeParams(obj) {
  let data = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] !== 'undefined') data[key] = obj[key];
  });
  return data;
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 获取 url 参数
 * @param {string} url  
 * @returns 出参 { id: 1, name: 'laowang' }
 */
export function getUrlParams() {
  const url = window.location.href;
  const index = url.indexOf('?');
  if (index === -1) return {};
  const params = url.slice(index + 1);
  return qs.parse(params);
}

export function setCookie(cname, cvalue, exdays = 0.1) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) { return c.substring(name.length, c.length); }
  }
  return "";
}

export const setToken = (value) => {
  return setCookie("seq", value);
}

export const getToken = () => {
  return getCookie("seq");
}




