// 页面路由配置
const routes = [
  {
    path: '/',
    redirect: 'clockIn',
  },
  {
    path: '/clockIn',
    meta: {
      title: '出勤打卡'
    },
    component: () =>
      import(/* webpackChunkName: "clockIn" */ '@/pages/clockIn/clockIn.vue')//出勤打卡
  },
  {
    path: '/clockInSuccess',
    meta: {
      title: '出勤打卡成功'
    },
    component: () =>
      import(/* webpackChunkName: "clockInSuccess" */ '@/pages/clockInSuccess/clockInSuccess.vue') //出勤打卡成功
  },
  {
    path: '/clockOut',
    meta: {
      title: '退勤打卡'
    },
    component: () =>
      import(/* webpackChunkName: "clockOut" */ '@/pages/clockOut/clockOut.vue') //退勤打卡
  },
  {
    path: '/clockOutSuccess',
    meta: {
      title: '退勤打卡成功'
    },
    component: () =>
      import(/* webpackChunkName: "clockOutSuccess" */ '@/pages/clockOutSuccess/clockOutSuccess.vue') //退勤打卡成功
  },
  {
    path: '/driverDrivingInfo',
    meta: {
      title: '列车编组运行情况'
    },
    component: () =>
      import(/* webpackChunkName: "driverDrivingInfo" */ '@/pages/driverDrivingInfo/driverDrivingInfo.vue') //驾驶信息
  },
  {
    path: '/driverDeclForm',
    meta: {
      title: '司机报单'
    },
    component: () =>
      import(/* webpackChunkName: "driverDeclForm" */ '@/pages/driverDeclForm/driverDeclForm.vue') //司机报单
  },
  {
    path: '/demo',
    meta: {
      title: 'demo'
    },
    component: () =>
      import(/* webpackChunkName: "demo" */ '@/pages/demo/demo.vue') //demo
  },
]

export default routes;